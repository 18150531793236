<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="announcements"
    >
      <b-table-column
        field="title"
        label="Titlu"
        sortable
        centered
        v-slot="props"
      >
        {{ props.row.title }}
      </b-table-column>
      <b-table-column label="Nume fisier" field="title" v-slot="props">
        <a
          @click="downloadFile(props.row.uri)"
          class="has-text-underline has-text-dark"
        >
          <span>
            <b-icon icon="file-download" size="is-small" />Descarca</span
          ></a
        >
      </b-table-column>
      <b-table-column
        field="date"
        label="Data"
        sortable
        centered
        v-slot="props"
      >
        <span class="tag is-success">
          {{ props.row.date | formatDate }}
        </span>
      </b-table-column>
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="props"
      >
        <div class="buttons is-right">
          <router-link
            :to="{ name: 'announcement.edit', params: { id: props.row.id } }"
            class="button is-small is-primary"
          >
            <b-icon icon="file-edit" size="is-small" />
          </router-link>
          <button
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
          >
            <b-icon icon="trash-can" size="is-small" />
          </button>
        </div>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Incarcare date...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nu exista date&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>

<script>
import ModalBox from "@/components/ModalBox";
import { openBlankPage } from "@/helpers/utils";
import { isNilOrEmpty } from "@/helpers/utils";
import moment from "moment";

export default {
  name: "AnnouncementsTable",
  components: { ModalBox },
  props: {
    announcements: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      clients: [],
      isLoading: false,
      paginated: true,
      perPage: 20,
    };
  },
  computed: {
    trashObjectName() {
      if (this.trashObject) {
        return (
          this.trashObject.title +
          " din data " +
          moment(this.trashObject.date).format("DD/MM/YYYY")
        );
      }

      return null;
    },
  },
  mounted() {
    this.isLoading = false;
    if (this.announcements && this.announcements > 0) {
      if (this.announcements.length > this.perPage) {
        this.paginated = true;
      }
    }
  },
  methods: {
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    downloadFile(file) {
      if (!isNilOrEmpty(file)) {
        openBlankPage(file);
      }
    },
    trashConfirm() {
      this.isModalActive = false;
      this.$emit("handleDeleteAnnouncement", this.trashObject.id);
      this.$buefy.snackbar.open({
        message: "Inregistrea a fost stearsa.",
        queue: false,
      });
    },
    trashCancel() {
      this.isModalActive = false;
    },
  },
};
</script>
